const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.europe.forwoodsafety.com',
    APP_CLIENT_ID: '6bg0h9rlhnqbc53ima86vaj3t4',
    APIGATEWAY: 'https://jgfj4lx09i.execute-api.eu-west-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://2pfnehl7tl.execute-api.eu-west-1.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'prod',
    VERSION: 'leadership-participation-container-1.7.0',
    HOSTNAME: 'https://leadership-participation.europe.forwoodsafety.com',
    ACCESS_ROLE: 'LeadershipParticipationAccess',
    MAINTAIN_DEFAULT_TARGETS_URL: 'https://iframe.europe.forwoodsafety.com/rm-targets-list' ,
    SET_INDIVIDUAL_TARGETS_URL: 'https://iframe.europe.forwoodsafety.com/user-target-list',
    SET_BULK_TARGETS_URL: 'https://iframe.europe.forwoodsafety.com/bulk-user-target',
    SITE_TARGET_DASHBOARD_URL: 'https://iframe.europe.forwoodsafety.com/site-target-report',
    TEAM_TARGET_DASHBOARD_URL: 'https://iframe.europe.forwoodsafety.com/dashboard-report',
    IFRAME_ORIGIN_URL: 'https://iframe.europe.forwoodsafety.com',
    CRM_HOMEPAGE_URL: 'https://iframe.europe.forwoodsafety.com/?v4_app=leaderships'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
